import { Box, Center, Heading, Image } from '@chakra-ui/react'
import { GetStaticProps } from 'next'
import Link from 'next/link'
import React from 'react'

import { Container } from '@/components/Container'
import { Head } from '@/components/Head'
import { Header } from '@/components/Header'
import { Layout } from '@/components/Layout'
import { SITE_URL } from '@/lib/constants'

export const getStaticProps: GetStaticProps = async () => {
  const id = 605

  return {
    props: {
      id,
    },
  }
}

type Props = {
  id: number
}

const Error404 = ({ id }: Props) => {
  const imageSize = '320px'

  return (
    <Layout>
      <Head
        path="/404"
        title="404 Not Found"
        description="The requested URL was not found."
        image={`${SITE_URL}/images/ukiyoe/${id}.png`}
        faviconId={id}
      />
      <Container>
        <Header />
        <Box>
          <Link href={`/details/${id}`} prefetch={false}>
            <a>
              <Center pt={16} bg="#677f75">
                <Image
                  fit="contain"
                  className="pixelated"
                  w={imageSize}
                  h={imageSize}
                  htmlWidth={imageSize}
                  htmlHeight={imageSize}
                  src={`/images/ukiyoe/b/${id}.png`}
                  fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNMry/9DwAFDAJc2KCJiQAAAABJRU5ErkJggg=="
                  alt={id.toString()}
                />
              </Center>
            </a>
          </Link>

          <Box mt="8">
            <Heading as="h1">404 Not Found</Heading>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default Error404
